.top-menu {
    position: absolute;
    z-index: $zindex-header-nav-overlay;
    top: 1.3rem;
    right: 4.5rem;
    display: flex;
    align-items: center;
    font-size: 0.8rem;

    .language-switch {
        position: relative;
        right: auto;
        top: auto;
    }

    @include mq($from: lg) {
        z-index: $zindex-topmenu;
        right: 3.5rem;
        top: 1.3rem;
    }

    .spotlight--memberfirm & {
        @include mq($until: lg) {
            top: -3.6rem;
        }
    }
}

.top-menu__search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.5rem;
    background-color: #fff;

    svg {
        width: 20px;
        height: 20px;
        fill: $brand-primary;
    }

    .spotlight--homepage & {
        @include mq($until: lg) {
            position: fixed;
            top: 1.3rem;
            right: 4.5rem;
        }
    }
}
