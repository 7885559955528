.member-firm-nav {
    position: relative;
    z-index: $zindex-member-firm-nav;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: map_get($breakpoints, 'xl');
    margin: 0 auto;
    padding-left: 30px;
    background: $brand-primary;

    &.is-stuck {
        left: 50%;
        border-bottom: 1px solid #fff;
        transform: translateX(-50%);
    }
}

.member-firm-nav__menu {
    display: flex;
    margin: 0;
    padding: 0;
    list-style: none;

    .has-select & {
        display: none;
    }

    li {
        margin-right: 0.5rem;
    }

    a {
        position: relative;
        display: block;
        text-decoration: none;
        color: #fff;
        font-size: 0.88rem;
        font-weight: 200;
        transition: 0.2s color;
        background-color: $brand-primary;

        &:hover,
        &.is-active {
            color: $brand-secondary;
        }

        &.button {
            padding: 4px 15px;
            font-weight: 300;
            color: $brand-primary;
            transition: 0.2s background-color;

            &.is-active {
                background-color: $brand-secondary-hover;
            }
        }

        @include mq($from: lg) {
            padding: 0.53rem 1rem;
        }
    }

    a.vacatures {
        color: $brand-primary;
        background-color: $brand-secondary;
    }

    @include mq($until: lg) {
        a {
            display: none;
        }

        a.mijn-hlb {
            display: block;
            margin-right: 0.5rem;
            font-size: 0;

            &:before {
                content: '';
                left: 1.5rem;
                display: inline-block;
                width: 1.6rem;
                height: 1.6rem;
                background: transparent url('#{$image-path}sprites/user.svg') no-repeat center;
            }
        }
    }
}

.member-firm-nav__select {
    display: none;
    padding: 10px;

    .has-select & {
        display: block;
    }
}
