.service-header {
    display: flex;
    flex-wrap: wrap;

    > * {
        width: 100%;
    }

    .spotlight__holder {
        padding: 0 map_get($layout-gutter-widths, xs);
    }
}

.service-header__faq-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.service-header__faq-content {
    padding: 4rem map_get($layout-gutter-widths, xs);
}

@include mq($from: sm) {
    .service-header__faq-content,
    .service-header .spotlight__holder {
        padding-left: map_get($layout-gutter-widths, sm);
        padding-right: map_get($layout-gutter-widths, sm);
    }
}

@include mq($from: md) {
    .service-header {
        > * {
            width: 50%;
        }
    }

    .service-header__faq-content {
        width: percentage(2/3);
        padding: 4rem 0;
    }
}
