.introduction {
    padding: 3rem 0 0;
    overflow: hidden;

    .link--back {
        margin-bottom: 0.75rem;
    }
}

.introduction__memberfirm-logo {
    display: block;
    margin: 0 0 3rem;

    svg {
        width: auto;
        height: 72px;
    }

    @media all and (-ms-high-contrast: none) {
        width: 180px; // ie11 needs a container so the logo doesn't center

        svg {
            width: 100%; // needed for ie11 have some aspect ratio
        }
    }

    path {
        fill: $brand-primary;
    }
}

.introduction__title {
    margin: 0 0 1rem;
    font-size: $font-size-h1;
}

.introduction__meta {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: -2px -5px;

    > * {
        flex: none;
        margin: 2px 5px;
    }
}

.introduction__content {
    margin: 1.5rem 0 0;

    .button {
        margin-top: 1.25rem;
        margin-left: 0;
        margin-right: 0;
    }
}

.introduction__text {
    margin: 0;
    font-size: 1.11rem;
}

.introduction__image-wrapper {
    @include image-background(20px, -20px);
    max-width: percentage(11/12);
    margin-top: 3rem;
    margin-bottom: 2rem;

    &:before {
        opacity: 0.6 !important;
    }
}

.introduction__date-wrapper {
    color: rgba($body-color, 0.5);
}

.introduction__event-meta {
    display: flex;
    border: 1px solid rgba($body-color, 0.3);
}

.introduction__event-meta-item {
    padding: 0.5rem 1rem;
    border-right: 1px solid rgba($body-color, 0.3);
    color: $body-color;

    svg {
        vertical-align: middle;
        margin: 0;
        fill: $body-color;
    }

    &:last-child {
        border-right: 0;
    }
}

@include mq($from: lg) {
    .introduction__image-wrapper {
        @include image-background(60px, -60px);
        margin-bottom: 4rem;

        &:before {
            opacity: 0.6 !important;
        }
    }
}

// when their is room for memberfirm logo in top of page pull it up
// @todo change value when HLB logo will be smaller - HLB-186
@include mq($from: 1420px) {
    .introduction--memberfirm-page {
        margin-top: -7rem;
    }
}
