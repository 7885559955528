$vacancy-background-color: #f2f7f9;

.vacancy-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    padding: 2rem;
    background-color: $vacancy-background-color;
    font-size: 0.88rem;
    transition: 0.2s background-color;
}

.vacancy-item__link {
    display: block;
    height: 100%;
    padding-bottom: 2rem;
    text-decoration: none;
    color: inherit;

    &:hover,
    &:focus {
        .vacancy-item {
            background-color: mix(#000, $vacancy-background-color, 4%);
        }
    }
}

.vacancy-item__header {
    position: relative;
    padding-left: 1.25rem;
    padding-bottom: 2rem;
}

.vacancy-item__heading {
    margin: 0;
    font-size: 1.33rem;
    line-height: 1.5;

    span {
        &:first-child {
            flex: 1 0 10px;
        }

        &:last-child {
            flex: 1 0 auto;
        }
    }
}

.vacancy-item__footer {
    padding-top: 0.75rem;
    border-top: 2px solid $brand-primary;
}

.vacancy-item__location {
    margin-bottom: 0.5rem;
}

.vacancy-item__member-firm,
.vacancy-item__category {
    font-weight: 700;
}

.vacancy-item__sprite {
    position: absolute;
    top: 10px;
    left: 0;
    width: 10px;
    height: 15px;
    fill: $brand-primary;
}
