.question-form {
    .form-field,
    .form-select {
        @include placeholder() {
            color: $brand-primary;
        }
    }

    .form__group.has-error {
        .form-field,
        .form-select {
            @include placeholder() {
                color: $form-group-error-color;
            }
        }
    }
}

.question-form__header,
.question-form__content {
    padding: 1.5rem 2rem;
    border: 1px solid mix(#fff, $brand-primary, 90%);
}

.question-form__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    background-color: mix(#fff, $brand-primary, 90%);

    @include mq($from: md) {
        align-items: center;
    }

    .question-form__state:checked ~ & .question-form__toggle {
        display: none;
    }
}

.question-form__content {
    display: none;

    .question-form__state:checked ~ & {
        display: block;
    }
}

.question-form__title-wrapper {
    display: flex;
    align-items: flex-start;
    flex: none;
    width: 100%;

    @include mq($from: md) {
        align-items: center;
    }

    @include mq($from: lg) {
        flex: 1 0 0;
    }

    &:before {
        content: '?';
        display: flex;
        flex: none;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        border-radius: 50%;
        color: #fff;
        font-size: 24px;
        background-color: $brand-secondary;
        font-family: $brand-font-family;
        font-weight: 700;
        line-height: 24px;
    }
}

.question-form__title {
    margin: 0;
    font-size: 16px;

    span {
        font-weight: 300;
    }
}

.question-form__submit-wrapper {
    margin-top: 1rem;
    text-align: center;
}

.question-form__state {
    display: none;
}

.question-form__toggle {
    flex: none;
    margin: 20px 0 0;

    @include mq($from: md) {
        margin: 2px 4px;
    }
}
