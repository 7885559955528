
/* == tingle v0.15.1 */

.tingle-modal * {
    box-sizing: border-box;
}

.tingle-modal {
    position: fixed;
    z-index: 1000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: none;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    visibility: hidden;
    -webkit-overflow-scrolling: touch;
    background: rgba(255, 255, 255, 0.85);
    opacity: 0;
    user-select: none;
    cursor: pointer;
}

/* confirm and alerts
-------------------------------------------------------------- */

.tingle-modal--confirm .tingle-modal-box {
    text-align: center;
}

/* modal
-------------------------------------------------------------- */

.tingle-modal--noOverlayClose {
    cursor: default;
}

.tingle-modal--noClose .tingle-modal__close {
    display: none;
}

.tingle-modal__close {
    position: fixed;
    z-index: 1000;
    top: 2rem;
    right: 2rem;
    width: 2rem;
    height: 2rem;
    padding: 0;
    border: none;
    background-color: transparent;
    color: $brand-primary;
    cursor: pointer;
}

.tingle-modal__close svg * {
    fill: currentColor;
}

.tingle-modal__closeLabel {
    display: none;
}

.tingle-modal__close:hover {
    color: $brand-primary;
}

.tingle-modal-box {
    position: relative;
    flex-shrink: 0;
    max-width: 95%;
    margin-top: auto;
    margin-bottom: auto;
    background: #fff;
    opacity: 1;
    cursor: auto;
    will-change: transform, opacity;

    @include mq($from: md) {
        max-width: 80%;
    }

    @include mq($from: lg) {
        max-width: 60%;
    }
}

.tingle-modal-box__footer {
    width: auto;
    padding: 1.5rem 2rem;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    background-color: #f5f5f5;
    cursor: auto;
}

.tingle-modal-box__footer:after {
    content: '';
    display: table;
    clear: both;
}

.tingle-modal-box__footer--sticky {
    position: fixed;
    z-index: 10001;
    bottom: -200px; /* TODO : find a better way */
    opacity: 1;
    transition: bottom 0.3s ease-in-out 0.3s;
}

/* state
-------------------------------------------------------------- */

.tingle-enabled {
    right: 0;
    left: 0;

    .tingle-modal {
        display: flex;
    }
}

.tingle-modal--visible .tingle-modal-box__footer {
    bottom: 0;
}

.tingle-enabled .tingle-content-wrapper {
    filter: blur(8px);
}

.tingle-modal--visible {
    visibility: visible;
    opacity: 1;
}

.tingle-modal--visible .tingle-modal-box {
    animation: scale 0.2s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}

.tingle-modal--overflow {
    padding-top: 8vh;
    overflow-y: scroll;
}

/* btn
-------------------------------------------------------------- */

.tingle-btn {
    display: inline-block;
    vertical-align: middle;
    margin: 0 0.5rem;
    padding: 1rem 2rem;
    border: none;
    background-color: #ececec;
    box-shadow: none;
    color: #fff;
    text-decoration: none;
    font-size: inherit;
    font-family: inherit;
    line-height: normal;
    cursor: pointer;
    transition: background-color 0.4s ease;
}

.tingle-btn--primary {
    background-color: #3498db;
}

.tingle-btn--danger {
    background-color: #e74c3c;
}

.tingle-btn--default {
    background-color: #34495e;
}

.tingle-btn--pull-left {
    float: left;
}

.tingle-btn--pull-right {
    float: right;
}

/* responsive
-------------------------------------------------------------- */

@media (max-width: 540px) {
    .tingle-modal {
        top: 0;
        display: block;
        width: 100%;
        padding-top: 60px;
    }

    .tingle-modal-box {
        width: auto;
        border-radius: 0;
    }

    .tingle-modal-box__content {
        overflow-y: scroll;
    }

    .tingle-modal--noClose {
        top: 0;
    }

    .tingle-modal--noOverlayClose {
        padding-top: 0;
    }

    .tingle-modal-box__footer .tingle-btn {
        display: block;
        float: none;
        width: 100%;
        margin-bottom: 1rem;
    }

    .tingle-modal__close {
        top: 0;
        right: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 60px;
        border: none;
        background-color: #2c3e50;
        box-shadow: none;
        color: #fff;
    }

    .tingle-modal__closeLabel {
        display: inline-block;
        vertical-align: middle;
        font-size: 1.6rem;
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    }

    .tingle-modal__closeIcon {
        display: inline-block;
        vertical-align: middle;
        width: 1.6rem;
        margin-right: 0.8rem;
        font-size: 0;
    }
}

/* animations
-------------------------------------------------------------- */

@keyframes scale {
    0% {
        transform: scale(0.9);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}
