.container__overview-page {
  padding-top: 3.5rem;
}

.vacancy-header-fixed {
  position: fixed;
  z-index: $zindex-topmenu;
  top: 1.3rem;
  right: 1rem;

  @include mq($from: lg) {
    right: 3.5rem;
  }
}
