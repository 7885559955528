.specialist-picker__header {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 1.5rem;
    background-color: mix(#fff, $brand-primary, 10%);
    color: #fff;

    > * {
        flex: none;
        width: 100%;
    }

    @include mq($from: md) {
        padding: 2rem 2.5rem;
    }
}

.specialist-picker__text {
    @include mq($from: md) {
        width: 60%;
        padding-right: 1rem;
    }
}

.specialist-picker__select {
    @include mq($from: md) {
        width: 40%;
    }
}

.specialist-picker__specialist {
    display: none;

    &.is-active {
        display: block;
    }
}
