.link {
    display: inline-block;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    .icon {
        fill: $link-color;
    }
}

%link--bordered {
    position: relative;
    display: inline-block;
    padding-bottom: 2px;
    text-decoration: none;

    &:hover,
    &:focus {
        text-decoration: none;

        &:after {
            left: 50%;
            right: 50%;
        }
    }

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        display: block;
        height: 1px;
        background-color: $body-color;
        transition-duration: 0.2s;
        transition-property: left, right;
        transition-timing-function: ease-out;
    }
}

%link--bordered-inversed {
    @extend %link--bordered;

    &:hover,
    &:focus {
        &:after {
            left: 0;
            right: 0;
        }
    }

    &:after {
        left: 51%; // Resolves default magic pixel
        right: 51%;
    }
}

.link--bordered {
    @include createBorderedLink();
}

.link--bordered-inversed {
    @include createBorderedInversedLink();
}

.link--bordered-dark {
    @include createBorderedLink($body-color);
}

.link--bordered-dark-inversed {
    @include createBorderedInversedLink($body-color);
}

.link--bordered-light {
    @include createBorderedLink(#fff);
}

.link--bordered-light-inversed {
    @include createBorderedInversedLink(#fff);
}

.link--light {
    color: #fff;

    svg,
    &.link--back svg {
        fill: #fff;
    }
}

.link--back {
    svg {
        display: inline-block;
        width: 9px;
        height: 13px;
        margin-right: 1rem;
        transform: rotate(180deg);
        fill: currentColor;
    }
}
