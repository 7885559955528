@use 'sass-svg-uri/svg-uri';

// hide label but keep them for screenreaders
.form--no-label {
    .form__label {
        @include sr-only();
    }
}

.form-field,
.form-select {
    font-size: 0.88rem;
}

.form-field {
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid $form-field-border-color;
    border-radius: 0;
    background: transparent;

    @include placeholder {
        color: #fff;
    }

    .form--light & {
        border-color: $form-field-border-color-light;
        color: $form-field-color-light;
    }

    .has-error & {
        border-color: $form-group-error-color;

        &:focus {
            box-shadow: none;
        }
    }

    &.contrast {
        color: $brand-primary;

        @include placeholder {
            color: $brand-primary;
        }
    }

    &.is-emphasized {
        border-width: 2px;
        font-weight: bold;
    }
}

.form__group--inline-submit {
    position: relative;

    .form-field {
        padding-right: 35px;
    }

    .button {
        position: absolute;
        top: 0;
        right: 0;
    }

    .button--compact-search {
        top: 50%;
        transform: translateY(-50%);
    }
}

.form-select {
    padding-left: 0;
    border: 0;
    border-bottom: 1px solid $form-field-border-color;
    border-radius: 0;
    background-color: transparent;
    color: $brand-primary;

    option {
        color: $brand-primary;
    }

    .form--light & {
        border-color: $form-select-border-color-light;
        color: $form-select-color-light;
        background-image: svg-uri($form-select-icon-light);

        option {
            color: $body-color;
        }
    }

    &.is-emphasized {
        border-width: 2px;
        font-weight: bold;
    }
}

.form__error-message {
    font-size: $font-size-small;
}

input:-webkit-autofill {
    -webkit-animation-name: autofill;
    -webkit-animation-fill-mode: both;

    .form--light & {
        -webkit-animation-name: autofill-light;
    }
}

@-webkit-keyframes autofill {
    to {
        color: $form-field-color;
        background: transparent;
    }
}

@-webkit-keyframes autofill-light {
    to {
        color: $form-field-color-light;
        background: transparent;
    }
}

.form-field__hidden {
    display: none;
}
