.member-firm-map__map {
    height: 100vh;
}

.member-firm-map {
    padding: 0;
}

.member-firm-map__nav {
    padding: 2rem;
    background-color: mix(#000, $brand-primary, 20%);
    color: #fff;

    .heading {
        color: #fff;
    }
}

.member-firm-map__nav-heading {
    display: inline-block;
    padding-right: 3rem;
}

.member-firm-map__nav-group {
    max-width: 380px;

    &:not(:last-child) {
        margin-bottom: 2rem;
    }

    .heading {
        a {
            @include createBorderedInversedLink(rgba(#fff, 0.8));
            color: #fff;
            text-decoration: none;
        }
    }

    span {
        color: rgba(#fff, 0.4);
    }
}

.member-firm-map__nav-link {
    @include createBorderedInversedLink(rgba(#fff, 0.4));
    margin: 0;
    padding: 0;
    border: none;
    background-color: transparent;
    font-size: $font-size-small;

    &:hover,
    &:focus {
        border: none;
        outline: 0;
        background-color: transparent;
        text-decoration: underline;
    }
}

.member-firm-map__info-heading {
    margin: 0;
    font-size: 18px;
}

.member-firm-map__location-heading {
    font-size: 1.5rem;
    font-weight: $heading-font-weight;
}

.member-firm-map__info-address {
    margin: 0;
    font-size: 16px;

    a {
        color: $body-color;
        text-decoration: none;
    }

    .button {
        margin-left: 0;
        margin-top: 15px;
        margin-bottom: 0;

        &:before {
            content: '';
            display: inline-block;
            width: 20px;
            height: 13px;
            margin-right: 10px;
            background-image: url('#{$image-path}sprites/email.svg');
        }
    }
}

@include mq($from: md) {
    .member-firm-map {
        display: flex;
        flex-direction: row;
    }

    .member-firm-map__map {
        flex: 1 1 auto;
        height: auto;
        min-height: 80vh;
    }

    .member-firm-map__nav {
        display: flex;
        flex-direction: column;
        justify-content: center;
        order: 2;
        flex: 0 1 50%;
    }
}

@include mq($from: xl) {
    .member-firm-map__nav {
        flex: 0 1 30%;
    }
}
