.faq-block {
    background-color: $brand-primary;
}

.faq-block__item {
    position: relative;
    padding: 2rem;
}

.faq-block__readmore-link {
    font-size: $font-size-small;

    svg {
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        margin-left: 2px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.faq-block__item-title {
    margin-bottom: 0.5em;
    font-size: $font-size-h5;
    color: #fff;
}

// only show first item on small screens
@include mq($until: lg) {
    .faq-block__item {
        &:not(:first-child) {
            display: none;
        }
    }
}

@include mq($from: lg) {
    .faq-block {
        display: flex;
        flex-direction: row;
    }

    .faq-block__item {
        display: flex;
        flex-direction: column;
        flex: none;
        width: percentage(1/3);
        padding: 2rem;
        border-right: 1px solid rgba($brand-tertiary, 0.5);
    }

    .faq-block__item-title {
        flex: 1 1 auto;
    }

    .faq-block__readmore-link {
        flex: 0 1 0;
    }
}

@include mq($from: xl) {
    .faq-block__item {
        padding: 3rem;
    }

    .faq-block__item-title {
        font-size: $font-size-h4;
    }
}
