.newsletter__subscription__block {
    position: fixed;
    bottom: 0;
    right: 0;
    background-color: $brand-tertiary;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    z-index: 9;
    padding: 1rem 2rem;
    max-width: 280px;

    a.button {
        color: $brand-primary;
        padding: 0.5rem 1.5em;

        &:after {
            content: none;
        }
    }

    &.js-hidden {
        display: none;
    }
}

@include mq($from: xs, $until: sm) {
    .newsletter__subscription__block {
        width: 100%;
        max-width: unset;
        flex-direction: row;
        align-items: center;

        a.button {
            margin-left: 0.5rem;
        }

        .newsletter__subscription__block__title {
            margin-bottom: 0;
            flex: 1;
        }

        .newsletter__subscription__block__close__button {
            top: 4px;
            right: 4px;
        }
    }
}

.newsletter__subscription__block__title {
    font-size: 1rem;
    font-weight: 600;
    color: inherit;
    margin-bottom: 0.5rem;
}

.newsletter__subscription__block__close__button {
    width: 28px;
    height: 28px;
    padding: 4px;
    border: 0;
    line-height: 20px;
    background-color: transparent;
    position: absolute;
    top: 8px;
    right: 8px;

    &:hover {
        svg path {
            fill: rgba(#fff, 0.7);
        }
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: #fff;
            transition: 0.2s fill;
        }
    }
}

.newsletter__popup {
    display: none;
    flex-direction: column;
    align-items: stretch;
    color: #fff;
    background-color: $brand-primary;
    padding: 2rem;

    .popup__title {
        font-size: 1rem;
        font-weight: 600;
        color: inherit;
    }

    .tingle-modal & {
        display: block;
    }
}

@include mq($from: lg) {
    .newsletter__popup .popup__title {
        font-size: 1.3rem;
    }
}
