/* stylelint-disable length-zero-no-unit */
$breakpoints: (
    xs: 0px,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1240px,
    xxl: 1440px
);
/* stylelint-enable */
