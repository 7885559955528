.employee {
    width: 100%;
    margin-bottom: 2rem;
    text-align: center;
    font-size: 0.88rem;

    .board-member-overview & {
        margin-bottom: 4rem;
    }
}

.employee__name {
    margin: 0;
    font-size: 24px;
}

.employee__image-button {
    position: relative;
    max-width: 200px;
    margin: 0 auto 1rem;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        z-index: $zindex-content;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        border-radius: 50%;
        background: linear-gradient(45deg, lighten($gradient-end-color, 5) -50%, lighten($gradient-start-color, 10) 150%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:before {
        content: attr(data-hover-text);
        position: absolute;
        z-index: $zindex-member-firm-nav;
        top: 50%;
        left: 50%;
        padding: 0 0.5rem;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        transform: translate(-50%, -50%);
        color: #fff;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:hover {
        &:after,
        &:before {
            opacity: 0.8;
        }
    }
}

.employee__image {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 1rem;
    border-radius: 50%;

    .board-member-overview & {
        max-width: 300px;
        border-radius: 0;
    }
}

.employee__job-title {
    display: block;
    font-weight: bold;
}

.employee__phone,
.employee__firm {
    display: block;
}

.employee__social {
    display: block;
    margin-top: 0.5rem;
    text-align: center;
}

.employee__social-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: $brand-primary;
    transition: background-color 0.2s ease-out;

    svg {
        width: 20px;
        height: 20px;
        fill: #fff;
    }

    &:hover {
        background-color: $brand-primary;

        svg {
            fill: #fff;
        }
    }
}

.employee__phone {
    display: block;
    margin-bottom: 1rem;
}

.employee__popup {
    display: none;
    flex-direction: column;
    align-items: stretch;
    max-width: 920px;
    color: #fff;
    background-color: $brand-primary;

    @include mq($from: md) {
        flex-direction: row;
    }

    .tingle-modal & {
        display: flex;
    }

    .employee__image {
        max-width: 150px;
    }

    .employee__name {
        color: #fff;
    }

    .employee__job-title {
        margin-bottom: 1rem;
        font-weight: 400;
        font-size: 0.9rem;
    }
}

.employee__popup__meta {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 340px;
    min-width: 340px;
    padding: 2rem;
    text-align: center;
    background: linear-gradient(45deg, lighten($gradient-end-color, 5) -50%, lighten($gradient-start-color, 10) 150%);
    font-size: $font-size-small;

    .button {
        margin-bottom: 1rem;
    }
}

.employee__popup__content {
    padding: 2rem;
    background-color: $brand-primary;

    p {
        font-size: $font-size-small;
    }
}

.highlighted--employees {
    // hide trailing item on breakpoint
    @include mq($from: md, $until: lg) {
        .layout__item:nth-child(n+4) {
            display: none;
        }
    }
}
