@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}Gotham-Book.woff2') format('woff2'),
    url('#{$font-path}Gotham-Book.woff') format('woff');
    font-style: normal;
    font-display: optional;
    font-weight: 400;
}

@font-face {
    font-family: 'Gotham';
    src: url('#{$font-path}Gotham-Bold.woff2') format('woff2'),
    url('#{$font-path}Gotham-Bold.woff') format('woff');
    font-style: normal;
    font-display: optional;
    font-weight: 700;
}
