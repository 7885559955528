.carousel {
    .slick-next:before,
    .slick-prev:before {
        color: $brand-primary;
        font-size: $font-size-h1;
        font-family: 'Gotham';
    }

    .slick-prev:before {
        content: '‹';
    }

    .slick-next:before {
        content: '›';
    }
}

.carousel-customer {
    .slick-track {
        display: flex;
        align-items: center;
    }
}

.carousel-customer__item {
    padding: 0 1.25rem;
}

.carousel-quotes {
    .slick-prev,
    .slick-next {
        top: 70px;
        width: 50px;
        height: 50px;
        border-radius: 50%;

        &:hover {
            background-color: #fff;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        position: absolute;
        margin-top: -23px;
        margin-left: -6px;
    }

    .slick-slide {
        width: auto;
    }
}

.carousel-quotes__item {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.carousel-quotes__quoter {
    font-size: $font-size-small;
}

.carousel-quotes__quote {
    display: inline-block;
    max-width: 220px;
    font-weight: 700;

    &:before {
        content: '“';
        position: absolute;
        margin: -3px 0 0 -30px;
        font-size: 3rem;
        line-height: 1;
        font-family: 'Georgia';
    }
}

.carousel-quotes__divider {
    width: 150px;
    height: 5px;
    margin: 1rem auto;
    border: 0;
    background: $gradient-inversed;
}

// allow the quote to have more space before next breakpoint
@include mq($from: 390px) {
    .carousel-quotes__quote {
        max-width: 330px;
    }
}

@include mq($from: sm) {
    .carousel-quotes__quote {
        font-size: $font-size-base;

        &:before {
            margin: -6px 0 0 -38px;
            font-size: 3.6rem;
        }
    }
}

@include mq($from: md) {
    .carousel-quotes__quote {
        max-width: 500px;
        font-size: $font-size-h5;

        &:before {
            margin: -7px 0 0 -56px;
            font-size: 4rem;
        }
    }
}

@include mq($from: lg) {
    .carousel-quotes__quote {
        font-size: $font-size-h3;

        &:before {
            margin-left: -80px;
            margin-top: -11px;
            font-size: 6rem;
        }
    }

    .carousel-quotes__quoter {
        font-size: $font-size-base;
    }
}

@include mq($from: xl) {
    .carousel-quotes__quote {
        max-width: 600px;

        &:before {
            margin-top: -11px;
        }
    }
}
