.newsletter__banner {
  background-color: rgba($brand-primary, 0.2);
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0.5rem;

  .button {
    margin: 0;
  }

  @include mq($from: md) {
    flex-direction: row;
    gap: 1rem;
    padding: 1.5rem 2rem;
  }
}

.newsletter__banner--content {
  text-wrap: balance;
  flex: 1;
}

.newsletter__banner--title {
  font-size: 1.3rem;
  margin-bottom: 0;
}

.newsletter__banner--subtitle {
  margin-bottom: 0;
}
