.label {
    display: inline-block;
    padding: 0.5em 0.75em;
    color: $brand-primary;
    background-color: $brand-secondary;
    transition: 0.2s background-color;

    &,
    &:hover,
    &:focus {
        text-decoration: none;
    }

    &:hover,
    &:focus {
        background-color: mix(#000, $brand-secondary, 10%);
    }
}
