$body-bg:                   #fff;
$brand-primary:             #005a77;
$brand-primary-highlight:   #036589;
$brand-secondary:           #fbba00;
$brand-secondary-hover:     mix(#fff, $brand-secondary, 15%);
$brand-tertiary:            #0093a7;
$link-color:                $brand-secondary;
$line-color:                #c8d3d9;
$line-dark-color:           $brand-tertiary;
$body-color:                $brand-primary;
$heading-color:             $brand-primary;

// Gradient colors
$gradient-start-color:      #f3c342;
$gradient-end-color:        #016589;

$gradient:                  linear-gradient(-45deg, $gradient-start-color 35%, $gradient-end-color 65%);
$gradient-inversed:         linear-gradient(45deg, $gradient-start-color 35%, $gradient-end-color 65%);

// Services
$service-light-theme-color: $line-color;
$service-dark-theme-color:  $brand-tertiary;
