.wysiwyg {
    strong {
        font-weight: 700;
    }

    p {
        + blockquote {
            margin-top: 2rem;
        }
    }

    blockquote {
        position: relative;
        margin-bottom: 4rem;
        font-size: 1.25rem;
        font-weight: 700;

        &.is-small {
            font-size: 1rem;
        }

        &:before {
            content: '“';
            display: block;
            width: 50px;
            height: 50px;
            font-size: 4.5rem;
            line-height: 1;
            font-family: serif;
        }

        &:after {
            content: '';
            position: absolute;
            left: 0;
            top: calc(100% + 1.5rem);
            width: 40%;
            height: 4px;
            background: $gradient-inversed;
        }
    }

    a {
        @include createBorderedInversedLink($link-color);
        line-height: 1.5;
    }

    figure {
        img {
            object-fit: contain;
        }
    }
}

@include mq($from: md) {
    .wysiwyg {
        p {
            + blockquote {
                margin-top: 3rem;
            }
        }

        blockquote {
            margin-bottom: 4.5rem;
            padding-left: 4.5rem;
            font-size: 1.75rem;

            &.is-small {
                font-size: 1rem;
            }

            &:before {
                position: absolute;
                left: 0;
                top: -20px;
                display: inline-block;
                font-size: 6rem;
            }

            &:after {
                left: 4.5rem;
            }
        }
    }
}
