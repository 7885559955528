.cards {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -20px;
}

.card--highlight {
    background: linear-gradient(-45deg, rgba($gradient-start-color, 0.5) 5%, rgba($gradient-end-color, 0.6) 95%);
}

.card {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    margin: 20px;
    padding: 1.5rem;
    border: 1px solid $line-color;
}

.card__content {
    flex-grow: 2;
    font-size: 0.9rem;
}

.card__title {
    margin: 0 0 0.5rem;
    font-size: $font-size-h4;
    font-weight: $heading-font-weight;
}

.card__sub {
    padding-top: 1rem;
}

@include mq($from: md) {
    .card {
        flex-basis: calc(50% - 40px);
        margin: 20px;
    }
}
