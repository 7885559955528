.spotlight {
    position: relative;
    overflow: hidden;
    color: #fff;

    html.ie & {
        display: flex;
        flex-direction: column;
    }
}

.spotlight--memberfirm,
.spotlight--homepage {
    overflow: visible; // don't know why it's normally hidden, but for the homepage it's required it's visible
}

.static-spotlight {
    &:before {
        content: '';
        position: absolute;
        z-index: $zindex-media-overlay;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient;
        opacity: 0.5;
    }
}

.spotlight--memberfirm {
    .container--spotlight__search {
        position: relative;
    }
}

// known IE11 bug is that vertical align doesn't work with min-height in flexbox
// can be fixed by setting a height of 0 but the side effects are that large content will go out of bounds.
// so we decided not to support vertical align on IE11
.spotlight__holder {
    display: flex;
    align-items: center;
    min-height: 60vh;
    padding: 2rem 0;

    html.ie & {
        width: 100%;
        height: 100%;
    }
}

.spotlight__image {
    position: absolute;
    z-index: $zindex-media;
    top: 0;
    left: 0;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    font-family: 'object-fit: cover'; // stylelint-disable-line font-family-no-missing-generic-family-keyword

    &.is-loaded {
        height: 100%;
    }
}

.spotlight__link-back {
    margin-bottom: 10px;
}

.spotlight__content,
.spotlight__question {
    position: relative;
    z-index: $zindex-content;
    width: 100%;
}

.spotlight__heading,
.carousel-spotlight__heading {
    margin: 0;
    color: inherit;
}

.spotlight__heading--center {
    text-align: center;
}

.spotlight__subtitle {
    font-weight: $heading-font-weight;
    font-size: $font-size-h3;
    color: inherit;
}

.spotlight__introduction {
    margin: 2rem 0 0;
    font-size: $font-size-h5;
}

.spotlight__button {
    margin-top: 2rem;
}

.spotlight__icon {
    position: relative;
    z-index: $zindex-content;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    margin: 0 0 1rem 0;
    border-radius: 50%;
    line-height: 50px;
    background-color: $brand-secondary;
    color: #fff;
    font-size: 1.75rem;
    font-weight: $heading-font-weight;
    font-style: normal;
}

.spotlight__highlighted-services,
.spotlight__memberfirm,
.spotlight__faq {
    position: relative;
    z-index: $zindex-content;
}

.spotlight__highlighted-services__heading,
.spotlight__memberfirm-heading,
.spotlight__faq-heading {
    font-size: 2rem;
    font-weight: $heading-font-weight;
    text-align: center;
    color: #fff;
}

.spotlight__search {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 0.8rem 0;
    transform: translateY(100%);
    background-color: #036589;
}

.spotlight__search-title {
    display: flex;
    flex: 1 0 auto;
    align-items: center;
    color: #fff;
    font-weight: 300;
    font-size: $font-size-h5;
}

.spotlight__search-field {
    min-width: 300px;
    padding: 0.425rem 0.75rem;
}

.spotlight__search__form-group {
    margin: 0;
}

.spotlight__search-icon {
    flex: 0 1 20px;
    width: 20px;
    height: 20px;
    margin-right: 0.75rem;
    fill: #fff;
}


.carousel-spotlight {
    // for initial load
    &:before {
        content: '';
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: $gradient;
        opacity: 0.5;
    }

    &:not(.slick-initialized) {
        .carousel-spotlight__item {
            display: none;

            &:first-of-type {
                display: block;
            }
        }
    }

    .slick-prev, .slick-next {
        width: 32px;
        height: 32px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        opacity: 0.6;

        &:hover {
            opacity: 1;
        }

        &:before {
            opacity: 1;
            line-height: 40px;
            height: 40px;
        }
    }

    .slick-prev {
        left: 0;
        z-index: 1;
    }

    .slick-next {
        right: 0;
        z-index: 1;
    }

    &.slick-dotted {
        // remove margin from dots
        margin-bottom: 0;
    }

    .slick-dots {
        bottom: 0;

        li.slick-active {
            button {
                &:before {
                    opacity: 1;
                }

                &:hover, &:focus-visible {
                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        li {
            button {
                &:before {
                    color: transparent;
                    opacity: 0;
                }

                &:hover, &:focus-visible {
                    &:before {
                        opacity: 0.5;
                    }
                }
            }
        }

        button {
            background-color: #fff;
            border-radius: 20px;
            padding: 2px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:before {
                width: 14px;
                height: 14px;
                border-radius: 14px;
                display: block;
                position: initial;
                background-color: $brand-secondary;
            }
        }
    }

    .slick-track {
        display: flex; // slides same height
    }

    .slick-slide {
        width: auto;
        height: inherit;  // slides same height
        position: relative;

        &:before {
            content: '';
            position: absolute;
            z-index: $zindex-media-overlay;
            top: 0;
            left: 0;
            bottom: 0;
            right: 0;
            background: $gradient;
            opacity: 0.5;
        }
    }
}

.carousel-spotlight__item {
    color: #fff;

    .spotlight__content {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 230px;
    }

    .spotlight__heading {
        display: -webkit-box;
        -webkit-line-clamp: 4;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    .button {
        margin-top: 1rem;
    }
}


@include mq($from: md) {
    .carousel-spotlight {
        .slick-prev, .slick-next {
            width: 40px;
            height: 40px;
        }
    }

    .spotlight__heading {
        font-size: 2.5rem;
    }

    .carousel-spotlight__item {
        .spotlight__heading {
            -webkit-line-clamp: 3;
        }

        .spotlight__content {
            height: 244px;
        }
    }

    .spotlight__holder {
        padding: 4rem 0;
    }

    .carousel-spotlight {
        .slick-prev {
            left: 1rem;
        }

        .slick-next {
            right: 1rem;
        }
    }
}

@include mq($from: lg) {
    .spotlight__holder {
        padding: 6rem 0;
    }

    .spotlight__heading:not(.spotlight__heading--small) {
        font-size: 3.5rem;
    }

    .carousel-spotlight__item {
        .spotlight__heading {
            -webkit-line-clamp: 4;
        }

        .spotlight__content {
            height: 400px;
        }
    }

    .spotlight__content {
        width: percentage(10/12);
        margin: 0 auto;
    }

    .spotlight__search-field-wrapper {
        display: flex;
        align-items: center;
    }

    .spotlight__search-title {
        margin: 0 1rem 0 0;
    }
}

@include mq($from: xl) {
    .spotlight__icon {
        margin: 0.5rem 0 0 auto;
    }

    .spotlight__content {
        width: percentage(8/12);
    }
}
