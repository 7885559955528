.vacancy__heading {
    margin: 0;
}

.vacancy__establishment-name {
    font-weight: 300;
    font-size: $font-size-h4;
}

.vacancy__block {
    background: #E7EEF1;
    padding: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    h3 {
        margin: 0;
        font-size: 36px;
    }

    @include mq($until: md) {
        display: block;
        padding: 1rem;

        h3 {
            font-size: 23px;
            margin-bottom: 1rem;
        }
    }
}
