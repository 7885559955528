.icon {
    width: 1em;
    height: 1em;
    margin: 0 0.2em;
    font-style: normal;
    font-weight: normal;
    speak: none;
    text-decoration: inherit;
    text-align: center;
    font-variant: normal;
    text-transform: none;
    line-height: 1em;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.icon--small {
    width: 0.8em;
    height: 0.8em;
}
