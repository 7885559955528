.section {
    padding: 3rem 0;
    overflow: hidden;
    fill: $brand-primary;
}

.section--small {
    padding: 1.5rem 0;
}

.section--large {
    padding: 4rem 0;
}

.section--blocks-homepage {
    padding-bottom: 0;

    .spotlight + & {
        padding-top: 15rem;
    }
}

.section--blocks-memberfirm {
    .spotlight + & {
        padding-top: 7rem;
    }
}

.section--primary {
    background: $brand-primary;
    color: #fff;
    fill: #fff;
}

.section--primary-light {
    background-color: rgba($brand-primary, 0.1);
    color: $brand-primary;
    fill: $brand-primary;
}

.section--secondary {
    background: linear-gradient(-45deg, rgba($gradient-start-color, 0.5) 35%, rgba($gradient-end-color, 0.6) 65%);
    color: #fff;
    fill: #fff;
}

.section--light {
    background: #fff;

    & + & {
        padding-top: 0;
    }

    .block--full-width & {
        padding: 0;
    }
}

.section__heading {
    font-size: 2rem;
    font-weight: $heading-font-weight;
    text-align: center;
}

.section__intro {
    margin-bottom: 2rem;
}

.section__actions {
    margin-top: 2rem;
    text-align: center;
}

.section__actions--small {
    margin-top: 1rem;
}

.section__heading-group {
    padding-top: 3rem;
    margin-bottom: 2rem;
}

.section__intro-content {
    width: 100%;
    max-width: 761px;
    margin-bottom: 2rem;
    font-size: $font-size-h5;
}

.section__heading-group--search {
    .heading {
        font-size: $font-size-h4;
        font-weight: 300;
    }

    .form-field {
        font-size: $font-size-h4;
        font-weight: 700;
        line-height: $heading-line-height;
    }
}

@include mq($from: sm) {
    .section__heading-group {
        display: flex;
        justify-content: space-between;

        .heading {
            flex: 1 1 0;
            margin-bottom: 0;
            padding-top: 0.625rem; // match with form fields
            font-size: $font-size-h5;
        }
    }

    .section__heading-group--search {
        justify-content: flex-start;

        .heading {
            flex: none;
            padding-right: 1rem;
        }
    }
}

@include mq($from: lg) {
    .section--small {
        padding: 3rem 0;
    }

    .section--blocks-homepage {
        padding-bottom: 0;

        .spotlight + & {
            padding-top: 9rem;
        }
    }

    .section--blocks-memberfirm {
        .spotlight + & {
            padding-top: 7rem;
        }
    }

    .section__intro {
        margin-bottom: 4rem;
    }

    .section__heading {
        font-size: $font-size-h1;
    }

    .section__actions {
        margin-top: 4rem;
    }

    .section__actions--small {
        margin-top: 2rem;
    }
}
