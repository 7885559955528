.block {
    padding: 1.5rem 0;

    &.block--text-toggle {
        padding: 0;
    }

    &.block--text ~ .block--text-toggle {
        margin-top: -1rem;
    }

    &.block--text + .block--text-toggle ~ .block--text {
        padding-top: 1rem;
    }

    &:first-child {
        padding-top: 0;
    }

    &:last-child {
        padding-bottom: 0;
    }
}

// breaks out of its container until it's full width
.block--full-width {
    position: relative;
    left: 50%;
    right: 50%;
    width: 100vw;
    margin-left: -50vw;
    margin-right: -50vw;
}

.block--cards {
    padding: 20px 0;
}

.block__figure {
    margin-bottom: -0.75em;
}

.image-hide {
    display: none;
}

.block__description {
    display: block;
    padding: 0.75em;
    font-size: 80%;
    font-style: italic;
    text-align: center;
}

.block__video-wrapper {
    position: relative;
    display: block;
    height: 0;
    padding: 0 0 percentage(9/16);
    overflow: hidden;

    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        border: 0;
    }
}

@include mq($from: lg) {
    .block {
        padding: 2rem 0;

        &.block--text-toggle {
            padding: 0;
        }

        .section--blocks-homepage & {
            &:first-child {
                padding-top: 0;
            }

            &:last-child {
                padding-bottom: 0;
            }
        }
    }

    .block--cards {
        padding: 20px 0;
    }
}

.block-faq {
    .list__item.list__item--faq {
        padding-left: 0.5rem;
    }

    .list__item-icon--faq {
        transform: rotate(90deg);
        transition: 0.3s ease-in-out;
    }

    .list__item.active {
        border-bottom: 1px solid $line-color;

        .list__item-icon--faq {
            transform: rotate(-90deg);
        }
    }
}

.block-faq__content {
    display: none;
    padding: 1.5rem;
    overflow: hidden;

    p {
        margin: 0;
    }
}

.block-toggle {
    display: none;
    padding-bottom: 1rem;
}

.block-toggle__button {
    &:hover {
        cursor: pointer;
    }

    .block-toggle__divider {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        margin: 8px 0;
        color: $brand-primary;

        &:before,
        &:after {
            content: '';
            flex-grow: 1;
            height: 1px;
            background: $brand-primary;
            line-height: 0;
        }

        &:before {
            margin-right: 8px;
        }

        &:after {
            margin-left: 8px;
        }

        .block-toggle__icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            margin-right: 8px;
            padding-left: 1px;
            padding-top: 2px;
            border-radius: 50%;
            border: 1px solid $brand-primary;
        }
    }
}
