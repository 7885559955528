.header {
    position: relative;
    z-index: $zindex-header-nav-overlay;
    display: flex;
    align-items: center;
    flex: none;
    padding: 1rem;
    background: $brand-primary;

    .wrapper--header-fixed & {
        position: fixed;
        left: 0;
        right: 0;
    }

    .header-nav-trigger {
        order: 1;

        > * {
            pointer-events: none;
        }
    }

    .header__image-holder {
        order: -1;
    }
}

.header__image-holder {
    flex: 1 0 auto;
    line-height: 0;
}

@include mq($from: lg) {
    .header {
        position: fixed;
        top: 0;
        left: 0;
        right: auto;

        .wrapper--header-fixed & {
            position: fixed;
            right: auto;
        }

        .mobile-nav-is-active & {
            position: fixed;
        }

        .header-nav-trigger {
            order: -1;
        }

        .header__image-holder {
            order: 1;
        }
    }

    .header__image-holder {
        padding-left: 2.5rem;
    }
}
