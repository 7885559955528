.articles-section--primary {
  background: $brand-primary;
  color: #fff;
  fill: #fff;
}

.articles-section {
  padding: 3rem 0;
}

.articles-section__header {
  margin: 0 auto 3rem;
  text-align: center;
}

@include mq($from: lg) {
  .articles-section__header {
    max-width: percentage(2/3);
  }
}
