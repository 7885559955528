.language-switch {
    position: absolute;
    z-index: $zindex-header;
    top: 1.35rem;
    right: 1rem;
    font-size: $font-size-small;
}

.language-switch__languages {
    display: none;
    flex-basis: 0;
    flex-direction: column;
    width: 0;
    padding: 0;
    margin: 0;
    overflow: hidden;
    list-style-type: none;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
    transition: 0.2s opacity;

    .language-switch__language {
        display: block;
        flex-basis: 2rem;
        margin-top: 2px;
        background-color: #fff;
        opacity: 0.6;
        font-size: $font-size-small;

        &:hover {
            opacity: 1;
        }

        a {
            display: block;
            height: 2rem;
            line-height: 2rem;
            color: $brand-primary;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
        }
    }
}

.language-switch__active {
    flex-basis: 2.5rem;
    width: 2.5rem;
    height: 2.5rem;
    line-height: 2.5rem;
    background-color: #fff;
    text-transform: uppercase;
    color: $brand-primary;
    font-weight: 700;

    span {
        border-bottom: 2px solid $brand-primary;
    }
}

.language-switch__container {
    align-items: center;
    padding: 0;
    border: 0;
    outline: 0;
    background-color: transparent;

    &:focus,
    &:hover {
        .language-switch__languages {
            position: absolute;
            display: flex;
            flex-basis: auto;
            width: 100%;
            opacity: 1;
        }
    }

    @include mq($until: lg) {
        display: none;
        pointer-events: none;
    }
}
