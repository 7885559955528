.button {
    padding: 0.5em 1.5em;
    margin: 2px 4px;
    border-radius: 30px;
    background-color: $brand-secondary;
    font-size: 0.88rem;
    font-weight: 400;
    color: $brand-primary;
    transition: 0.2s background-color, 0.2s color;

    &:hover,
    &:focus {
        background-color: $brand-secondary-hover;
    }
}

.button-group {
    margin: -2px -4px;
}

.button--link {
    padding: 0;
    background-color: transparent;
    color: $brand-secondary;

    &:hover,
    &:focus {
        text-decoration: underline;
        background-color: transparent;
        color: mix(#000, $brand-secondary, 10%);
    }
}

.button--compact-submit {
    width: 20px;
    height: 20px;
    margin: 0.8rem 0;
    padding: 0;
    text-indent: -9999px;
    background-image: url('#{$image-path}/sprites/chevron-right.svg');
    background-repeat: no-repeat;
    background-size: 10px 10px;
    background-position: center;
}

.button--compact-search {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 36px;
    height: 36px;
    margin: 0;
    padding: 0;
    background-color: transparent;

    &:hover, &:focus-visible, &:focus {
        background-color: transparent;
    }
}

.button-search-icon {
    width: 20px;
    height: 20px;
}

.button--icon {
    svg {
        display: inline-block;
        vertical-align: middle;
        width: 1em;
        margin-right: 0.5rem;
        fill: $brand-primary;
    }
}
