.services-section--primary {
    background: $brand-primary;
    color: #fff;
    fill: #fff;
}

.services-section__header {
    margin: 0 auto 3rem;
    text-align: center;
}

.services-section.services-section--light {
    padding: 3rem 0;
    background-color: rgba($brand-primary, 0.2);
}

.services-section__title {
    margin: 0 0 1rem;
    font-size: $font-size-h3;
    color: #fff;

    .services-section--light & {
        color: $brand-primary;
    }
}

.service__anchor {
    display: block;
    height: 100%;
    color: #fff;
    fill: #fff;
    text-align: center;
    text-decoration: none;
    font-size: 0.88rem;
    margin-bottom: 1rem;

    .services-section--light & {
        color: $brand-primary;
        fill: $brand-primary;
    }

    &:hover {
        .service__image-button {
            &:after,
            &:before {
                opacity: 0.8;
            }
        }
    }
}

.service__icon-wrapper {
    display: block;
    margin-bottom: 1rem;
}

.service__icon {
    width: 3rem;
    height: 3rem;
    fill: currentColor;
}

.service__title {
    margin: 0;
    font-size: 1rem;
    font-weight: 600;
    color: inherit;
}

.service__image {
    width: 100%;
    max-width: 200px;
    margin: 0 auto 1rem;
    border-radius: 50%;
}

.service__image-button {
    position: relative;
    max-width: 200px;
    margin: 0 auto 1rem;
    cursor: pointer;

    &:after {
        content: '';
        position: absolute;
        z-index: $zindex-content;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        display: block;
        border-radius: 50%;
        background: linear-gradient(45deg, lighten($gradient-end-color, 5) -50%, lighten($gradient-start-color, 10) 150%);
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }

    &:before {
        content: attr(data-hover-text);
        position: absolute;
        z-index: $zindex-member-firm-nav;
        top: 50%;
        left: 50%;
        padding: 0 0.5rem;
        border: 1px solid #fff;
        border-radius: 0.5rem;
        transform: translate(-50%, -50%);
        color: #fff;
        opacity: 0;
        transition: opacity 0.2s ease-in-out;
    }
}

.service__short-text {
    font-size: 0.8rem;
}

@include mq($from: lg) {
    .services-section__header {
        max-width: percentage(2/3);
    }

    .service__title {
        font-size: 1.3rem;
    }
}

.highlighted-services-block {
    display: flex;
    flex-direction: row;
    background-color: $brand-primary;
}

.highlighted-services-block__item {
    position: relative;
    display: none;
    width: 100%;
    padding: 1.3rem 1rem;
    border-right: 1px solid rgba($brand-tertiary, 0.5);

    &:first-child {
        display: flex;
    }
}

.highlighted-services__anchor {
    display: flex;
    align-items: flex-start;
    color: #fff;
    text-decoration: none;

    svg {
        fill: #fff;
    }

    .service__icon-wrapper {
        margin-right: 1.2rem;
        margin-top: 0.4rem;
    }
}

.highlighted-services-block__readmore-link {
    color: $brand-secondary;
    font-size: $font-size-small;

    svg {
        fill: $brand-secondary;
        display: inline-block;
        width: 0.5em;
        height: 0.5em;
        margin-left: 2px;
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
    }
}

.highlighted-services-block__item-title {
    margin-bottom: 0.5em;
    font-size: $font-size-h5;
    color: #fff;
}

.highlighted-services-block__more {
    position: absolute;
    bottom: 1px;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 0.8rem 0;
    transform: translateY(100%);
    background-color: $brand-primary-highlight;

    a {
        display: flex;
        align-items: center;
        color: #fff;
        font-size: 0.8rem;
        text-decoration: none;

        svg {
            margin-left: 0.5rem;
            fill: #fff;
        }
    }
}

@include mq($from: md) {
    .highlighted-services-block__item {
        display: flex;
        flex: none;
        width: percentage(1/2);

        &:last-child {
            display: none;
        }
    }
}

@include mq($from: lg) {
    .highlighted-services-block__item {
        width: percentage(1/3);

        &:last-child {
            display: flex;
        }
    }

    .highlighted-services-block__item-title {
        flex: 1 1 auto;
    }

    .highlighted-services-block__readmore-link {
        flex: 0 1 0;
    }
}

@include mq($from: xl) {
    .highlighted-services-block__item-title {
        font-size: $font-size-h4;
    }
}
