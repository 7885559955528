@mixin image-background($offset-top, $offset-left: $offset-top) {
    position: relative;
    z-index: 1;

    &:not(.lazyload-wrapper),
    &.lazyload-wrapper.is-loaded {
        overflow: visible;

        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: $offset-top;
            left: $offset-left;
            display: block;
            width: 100%;
            height: 100%;

            // overrides lazyload-wrapper styling
            margin: 0;
            border: none;
            border-radius: 0;
            opacity: 0.8;
            background: $gradient;
            transition: 0.25s transform;
        }
    }
}
