.lazyload-wrapper {
    position: relative;
    display: block;
    background-color: mix($brand-primary, #fff, 75%);

    &:before,
    &:after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        width: 1.5em;
        height: 1.5em;
        margin-top: -0.75em;
        margin-left: -0.75em;
        border: 2px solid transparent;
        border-radius: 50%;
    }

    &:before {
        border-color: rgba(#fff, 0.25);
    }

    &:after {
        border-top-color: #fff;
        animation: spin 1.5s linear infinite;
    }

    &.is-loaded {
        background: none;

        &:before,
        &:after {
            content: none;
        }
    }
}

.lazyload {
    position: relative;
    z-index: 1;
    display: block;
    max-width: 100%;
    opacity: 0;
    text-align: center;
    transition: opacity 0.4s;

    &.is-loaded {
        opacity: 1;
    }
}
