.footer {
    flex: none;
    background: $brand-primary;

    a:not(.icon) {
        @include createBorderedInversedLink(#fff);
    }
}

.footer__top {
    ul {
        margin: 0;
        padding: 0;
    }

    li {
        display: block;
    }
}

.footer__top-social {
    a:hover {
        svg path {
            fill: rgba(#fff, 0.7);
        }
    }

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: #fff;
            transition: 0.2s fill;
        }
    }

    li {
        display: inline-block;
        margin-right: 1rem;

        &:first-child {
            display: block;
            width: 100%;
        }
    }
}

.footer__top-member-firm {
    color: #fff;
    font-size: $font-size-small;
    text-transform: uppercase;
    font-weight: bold;
}

.footer__top-nav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 0.5rem;
    padding: 1.5rem 0;
    border-top: 1px solid $line-dark-color;
}

.footer__top-nav-end {
    justify-self: end;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.footer__top-links {
    flex-basis: 100%;
    justify-content: flex-end;
    flex-wrap: wrap;
    font-size: $font-size-small;
    display: flex;
    gap: 1rem;

    @include mq($from: md) {
        gap: 2rem;
    }
}

.footer__top-member-info {
    li {
        margin-right: 2rem;
        color: #fff;
        font-size: 0.7rem;

        &:last-child {
            margin-right: 0;
        }
    }
}

.footer__newsletter {
    margin: 0 auto;
    padding: 3rem 0 2rem;
}

.footer__heading {
    font-size: $font-size-h3;
    font-weight: $heading-font-weight;
    color: #fff;
    text-align: center;
}

.footer__bottom {
    padding: 2rem 0;
    background-color: mix(#000, $brand-primary, 10%);
    font-weight: 200;
}

.footer__bottom-list {
    a {
        font-size: 14px;
        color: #fff;
        text-decoration: none;
    }
}

.footer__bottom-info {
    display: block;
    color: #fff;
    font-size: $font-size-small;
}

.footer__bottom-column-header {
    display: flex;
    align-items: center;
    min-height: 48px;
    margin: 0.5rem 0;
}

.footer__bottom-heading {
    margin: 0;
    color: #fff;
    font-size: $font-size-small;
    font-weight: $heading-font-weight;
    text-transform: uppercase;
}

@include mq($from: md) {
    .footer__top-holder {
        display: flex;
        justify-content: space-between;
    }

    .footer__top-nav {
        li {
            display: inline-block;
            vertical-align: middle;
            line-height: 1;
        }
    }

    .footer__newsletter {
        max-width: percentage(10/12);
        padding: 8rem 0 6rem;
    }

    .footer__bottom-column-header {
        margin: 0 0 0.5rem;
    }
}

@include mq($from: lg) {
    .footer__top-member-info {
        align-self: center;
    }

    .footer__top-social {
        li:first-child {
            display: inline-block;
            width: auto;
        }
    }

    .footer__bottom {
        padding: 4rem 0;
    }

    .footer__bottom-list {
        ul {
            display: flex;
            justify-content: space-between;
        }
    }
}

@include mq($from: xl) {
    .footer__newsletter {
        max-width: percentage(7/12);
        padding: 6rem 0;
    }
}
