html {
    overflow-y: auto;
}

html,
body {
    height: 100%;
    font-size: $small-base-font-size;

    @include mq($from: $typography-breakpoint) {
        font-size: $base-font-size;
    }
}

body {
    &.mobile-nav-is-active {
        overflow: hidden;
    }
}

.wysiwyg {
    *:last-child {
        margin-bottom: 0;
        padding-bottom: 0;
    }
}

.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.content {
    flex: 1 0 auto;
}
