.heading__icon {
    display: inline-flex;
    align-self: center;
    width: 1em;
    height: 1em;
}

.heading--underlined {
    position: relative;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;

    &:after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        display: block;
        width: 100%;
        height: 5px;
        background: $gradient;
    }
}

.heading--underlined-light {
    position: relative;
    padding-bottom: 1.5rem;
    margin-bottom: 2rem;
    border-bottom: 3px solid #fff;
}

.heading--flat {
    margin: 0;
}

.heading--secondary {
    color: $brand-secondary;
}

.heading--h2 {
    font-size: $font-size-h2;
    font-weight: $heading-font-weight;
}

.heading--h3 {
    font-size: $font-size-h3;
    font-weight: $heading-font-weight;
}

.cookie-alert__title {
    font-weight: $heading-font-weight;
}
