.pagination {
    position: relative;
    display: flex;
    justify-content: center;
    margin: 0.5rem 0 2rem;
    text-align: center;
    font-size: 0.8rem;

    .first,
    .last {
        display: none;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        color: $body-color;
        text-decoration: none;
        transition: 0.25s color;
    }

    span {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        width: 30px;
        height: 30px;
        margin: 0 0.1rem;
        background: transparent;
        transition: 0.25s background;

        svg {
            fill: $brand-primary;
        }

        &:hover {
            background: #e5e5e5;
        }
    }

    .current {
        border: 1px solid $brand-primary;
        color: $body-color;

        &:hover {
            background: #fff;
        }
    }

    .icon {
        width: 16px;
        height: 16px;

        path {
            transition: 0.25s fill;
        }
    }
}
