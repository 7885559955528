// Paths
$base-path:                 '../../../';
$font-path:                 $base-path + 'fonts/';
$image-path:                $base-path + 'images/';

// Typography
$base-font-family:          'Gotham', 'Helvetica Neue', sans-serif;
$base-font-weight:          400;
$small-base-font-size:      16px;
$base-font-size:            18px;
$brand-font-family:         $base-font-family;
$heading-font-family:       $brand-font-family;

// Breakpoints
$typography-breakpoint:     md;
