$form-field-border-color: $brand-primary;
$form-select-border-color: $brand-primary;
$form-select-icon-color: $brand-primary;
$form-field-focus-border-color: $brand-tertiary;
$form-field-color-light: #fff;
$form-select-color-light: #fff;
$form-select-border-color-light: $line-color;
$form-field-border-color-light: $line-color;
$form-select-icon-color-light: #fff;
$form-select-icon-light: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36 36" width="36" height="36"><path fill="#{$form-select-icon-color-light}" d="M18 21L30.3 9.2c.3-.3.8-.3 1.1 0l2.2 2.1c.3.3.3.8 0 1.1l-15 14.4c-.2.1-.4.2-.6.2-.2 0-.4-.1-.6-.2l-15-14.4c-.3-.3-.3-.8 0-1.1l2.2-2.1c.3-.3.8-.3 1.1 0L18 21z"/></svg>' !default; /* stylelint-disable-line */
