.header-nav {
    position: fixed;
    z-index: $zindex-header;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    overflow-y: auto;
    transform: translateY(-100%);
    background: $brand-primary;
    transition: 0.2s transform;

    .mobile-nav-is-active & {
        transform: translateY(0);
    }

    > .container {
        height: 100%;
    }
}

.header-nav__logo {
    height: 48px;
}

.header-nav__home-link {
    position: absolute;
    right: 0;
    top: calc(50% - 10px);
    display: flex;
    align-items: center;
    transform: translateX(115%);
    color: $brand-secondary;
    white-space: nowrap;
    font-size: 0.8rem;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }

    svg {
        margin: 0 0.5rem 0 0;
        fill: $brand-secondary;
    }

    @include mq($until: lg) {
        display: none;
    }
}

.header-nav__home-link--mobile {
    top: 1.5rem;
    left: 1.3rem;
    right: auto;
    display: flex;
    transform: none;

    @include mq($from: lg) {
        display: none;
    }
}

.header-nav__holder {
    padding-top: 80px;
    padding-left: 20px;
    padding-right: 20px;
}

.header-nav__section {
    & + & {
        margin-top: 4rem;
    }
}

.header-nav__heading {
    display: inline-block;
    margin-bottom: 2rem;
    padding-right: 5.5rem;
    padding-bottom: 2rem;
    border-bottom: 3px solid #fff;
    color: #fff;
    font-size: 1.33rem;
    font-weight: $heading-font-weight;
}

.header-nav__languages {
    margin-bottom: 20px;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        font-size: 0;
    }

    li {
       display: inline-block;

        &:last-child {
            a:after,
            span:after {
                content: '';
                display: none;
            }
        }
    }

    a {
        color: rgba(#fff, 0.4);
        font-size: $base-font-size;
        text-decoration: none;

        &:after {
            content: '|';
            display: inline-block;
            margin: 0 0.4rem;
        }
    }

    .is-active {
        color: #fff;
        font-size: $base-font-size;
        text-decoration: underline;

        &:after {
            content: '|';
            display: inline-block;
            margin: 0 0.4rem;
            color: rgba(#fff, 0.4);
        }
    }
}

.header-nav__list {
    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    li {
        + li {
            margin-top: 0.25rem;
        }
    }

    a {
        @include createBorderedInversedLink(#fff);
        font-weight: 200;
    }

    .header-nav__lang {
        margin-top: 2rem;
    }
}

.header-nav__list--member-firm {
    li + li {
        margin-top: 1rem;
    }

    a {
        @include createBorderedInversedLink(rgba(#fff, 0.4));
        display: block;

        &:hover {
            span:after {
                margin-left: 9px;
            }
        }
    }

    span {
        &:first-child {
            display: flex;
            align-items: center;
            color: #fff;

            &:after {
                content: '';
                display: inline-block;
                width: 9px;
                height: 13px;
                margin-left: 5px;
                background-image: url('#{$image-path}/sprites/chevron-right-light.svg');
                transition: 0.2s margin-left;
            }
        }

        &:last-child {
            color: rgba(#fff, 0.4);
            font-size: 0.88rem;
        }
    }
}

@include mq($from: md) {
    .header-nav__holder {
        display: flex;
        height: 100vh;
        padding: 0;

        @supports (object-fit: cover) {
            height: auto;
            min-height: 100vh;
        }
    }

    .header-nav__languages {
        display: none;
    }

    .header-nav__section {
        display: flex;
        justify-content: center;
        flex: 0 1 auto;
        width: 100%;
        padding-top: 20vh;
        padding-left: 2rem;
        padding-right: 2rem;

        & + & {
            margin-top: 0;
        }
    }

    .header-nav__section--secondary {
        background: mix(#000, $brand-primary, 20%);
    }
}
