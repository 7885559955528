.memberfirm-block {
    background-color: $brand-primary;

    &:not(:first-of-type) {
        position: absolute;
        left: 0;
        right: 0;
    }
}

.memberfirm-block__item {
    display: block;
    padding: 1rem 1.5rem;
    color: #fff;
    text-decoration: none;
    box-shadow: 0 0 0 1px $brand-primary-highlight inset;
    background-image: url('#{$image-path}/sprites/chevron-right-light.svg');
    background-repeat: no-repeat;
    background-size: 20px 20px;
    background-position: right 1rem center;
    transition: 0.2s background-color;

    &:hover {
        background-color: $brand-primary-highlight;
    }
}

.memberfirm-block__item__name {
    display: block;
    font-weight: 800;
    font-size: 1.3rem;
}

.memberfirm-block__item__place {
    display: block;
    font-size: 0.9rem;
}

@include mq($from: lg) {
    .memberfirm-block {
        display: grid;
        box-sizing: border-box;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);
    }

    .memberfirm-block__item__place {
        font-size: 0.8rem;
    }
}
