.share {
    position: fixed;
    z-index: $zindex-header;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 0;

    @include mq($until: md) {
        bottom: 0;
        left: 0;
        right: 0;
        flex-direction: row;
    }

    @include mq($from: md) {
        top: 30%;
        left: 5px;
        display: block;

        &.js-fade-in-on-scroll {
            opacity: 0;
            transition: opacity 0.2s ease;

            &.fade-in {
                opacity: 1;
            }
        }
    }
}

.share-btn { // overrides .btn
    display: flex;
    align-items: center;
    justify-content: space-around;
    width: 2.5rem;
    height: 2.5rem;
    margin: 0;
    margin-bottom: 0.5rem;
    border: 1px solid $brand-primary;
    font-size: 0;
    background: #fff;

    @include mq($until: md) {
        margin-bottom: 0;
        border-right: 0;
        border-bottom: 0;

        &:last-child {
            border-right: 1px solid $brand-primary;
        }
    }

    svg {
        height: 0.8rem;
        fill: $brand-primary;
    }

    &:hover {
        background: linear-gradient(315deg, lighten($gradient-start-color, 10) -10%, lighten($gradient-end-color, 10) 115%);

        svg {
            fill: $body-bg;
        }
    }
}

.share-title {
    display: block;
    width: 100%;
    margin: 0 0 5px;
    font-family: $brand-font-family;
    text-align: left;
    font-size: $font-size-small;
    font-weight: 700;

    @include mq($until: md) {
        width: auto;
        height: 2.5rem;
        padding: 0 1rem;
        margin-bottom: 0;
        border: 1px solid $brand-primary;
        border-right: 0;
        border-bottom: 0;
        line-height: 2.5rem;
        background-color: #fff;
    }

    @include mq($from: md) {
        font-size: $font-size-small;
    }
}
