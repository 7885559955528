/* stylelint-disable */
@mixin placeholder {
    &::-webkit-input-placeholder {
        @content
    }

    &:-moz-placeholder {
        @content
    }

    &::-moz-placeholder {
        @content
    }

    &:-ms-input-placeholder {
        @content
    }
}
/* stylelint-enable */

@mixin createBorderedLink($color: $link-color) {
    @extend %link--bordered;
    color: $color;

    &:after {
        background-color: $color !important;
    }
}

@mixin createBorderedInversedLink($color: $link-color) {
    @extend %link--bordered-inversed;
    color: $color;

    &:after {
        background-color: $color !important;
    }
}
