$font-size-h1:                      2.5rem !default;
$font-size-h2:                      2rem !default;
$font-size-h3:                      1.75rem !default;
$font-size-h4:                      1.5rem !default;
$font-size-h5:                      1.25rem !default;
$font-size-h6:                      1rem !default;
$font-size-base:                    1rem !default;
$font-size-small:                   0.8rem !default;

$base-line-height:                  1.688 !default;
$heading-line-height:               1.333 !default;
