$marker-width: 48px;
$marker-height: 69px;

.marker {
    position: absolute;
    width: $marker-width;
    height: $marker-height;
    transform: translateY(-100%);
    background-image: url('#{$image-path}marker.svg');
}

.marker__infowindow {
    position: absolute;
    z-index: 2;
    left: calc(50% + #{$marker-width / 2});
    top: -$marker-height;
    width: 300px;
    padding: 15px;
    transform: translate(-50%, -100%);
    background-color: #fff;

    &:after {
        content: '';
        position: absolute;
        left: 50%;
        bottom: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 10px 10px 0 10px;
        border-color: #fff transparent transparent;
        transform: translateX(-50%);
    }
}
