.list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.list__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.5rem 0;
    color: $body-color;
    text-decoration: none;

    &:hover,
    &:focus {
        color: $brand-secondary;
    }
}

.list__item--bold {
    font-weight: $heading-font-weight;
}

.list__item-icon {
    display: inline-flex;
    flex: none;
    width: 0.5rem;
    height: 1rem;
    margin-right: 1rem;
    fill: $brand-secondary;
}

.list__item-icon--rotated {
    transform: rotate(180deg);
}

.list__item-text {
    flex: 1 1 auto;
}

.list--bordered {
    .list__item {
        padding: 1rem 0;
        border-top: 1px solid $line-color;
    }

    .list__item:last-child {
        border-bottom: 1px solid $line-color;
    }
}
