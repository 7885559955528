@include mq($until: md) {
    h1 {
        font-size: $font-size-h2;
    }

    h2 {
        font-size: $font-size-h3;
    }

    h3 {
        font-size: $font-size-h4;
    }

    h4 {
        font-size: $font-size-h5;
    }

    h5 {
        font-size: $font-size-h6;
    }

    h6 {
        font-size: $font-size-h6;
    }
}
