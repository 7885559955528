.mpForm:not([novalidate='novalidate']) {
    opacity: 0;
}

.mpFormTable {
    padding: 0;
    list-style-type: none;
}

.mpQuestionTable {
    position: relative;
    margin-bottom: 1rem;
    font-size: 0.88rem;
}

.mpFormLabel {
    label {
        margin-bottom: 0;
    }
}

.mpFormField {
    input {
        width: 100%;
        max-width: 400px;
        padding-left: 0;
        border: 0;
        border-bottom: 1px solid $form-field-border-color;
        border-radius: 0;
        background: transparent;

        &[type='radio'],
        &[type='checkbox'] {
            position: absolute;
            left: -9999px;

            & + label {
                position: relative;
                display: inline-block;
                padding-left: 1.5rem;
                cursor: pointer;
                line-height: 1rem;
                color: $body-color;

                &:before,
                &:after {
                    content: '';
                    position: absolute;
                }

                &:before {
                    left: 0;
                    top: 0;
                    width: 18px;
                    height: 18px;
                    border: 1px solid $body-color;
                    border-radius: 100%;
                    background: #fff;
                }

                &:after {
                    top: 3px;
                    left: 3px;
                    width: 12px;
                    height: 12px;
                    border-radius: 100%;
                    background: $body-color;
                    transition: transform 0.2s ease, opacity 0.2s ease;
                }
            }

            &:not(:checked) + label:after {
                transform: scale(0);
                opacity: 0;
            }

            &:checked + label:after {
                transform: scale(1);
                opacity: 1;
            }
        }

        &[type='checkbox'] {
            & + label {
                &:before,
                &:after {
                    border-radius: 0;
                }
            }
        }

        @include placeholder {
            color: $body-color;
        }
    }

    .sublabel {
        color: rgba($body-color, 0.5);
        font-style: italic;
    }

    textarea {
        width: 100%;
        max-width: 400px;
        padding-left: 0;
        border: 1px solid $form-field-border-color;
        border-radius: 0;
        background: transparent;
    }

    select {
        display: block;
        width: 100%;
        max-width: 400px;
        margin: 0;
        padding: 0.6em 1.4em 0.5em 0.8em;
        border: 1px solid $form-field-border-color;
        border-radius: 0;
        font-size: $base-font-size;
        color: $body-color;
        line-height: 1.3;
        box-shadow: none;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        background-image: url('#{$image-path}/sprites/chevron-down.svg');
        background-repeat: no-repeat, repeat;
        background-position: right 0.7em top 50%, 0 0;
        background-size: 0.65em auto, 100%;
    }
}

.mpFormField ul {
    padding: 0;
    list-style-type: none;

    li {
        display: block;
        margin-bottom: 0.5rem;

        @include mq($from: md) {
            display: inline-block;
            vertical-align: top;
            margin-right: 0.5rem;
        }

        .mpOtherCustomInput {
            opacity: 1;
            transition: opacity 0.2s ease;
        }

        .mpOtherCustomInput[disabled='disabled'] {
            opacity: 0;
        }
    }
}

.mpradioButtonTable {
    .mpFormDescription {
        text-align: center;
    }

    input[type='radio'] {
        position: relative;
        left: 50%;
        width: 20px;
        height: 20px;
        transform: translateX(-50%);

        &:before,
        &:after {
            content: '';
            position: absolute;
        }

        &:before {
            left: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 1px solid $body-color;
            border-radius: 100%;
            background: #fff;
        }

        &:after {
            top: 3px;
            left: 3px;
            width: 12px;
            height: 12px;
            border-radius: 100%;
            background: $body-color;
            transition: transform 0.2s ease, opacity 0.2s ease;
        }

        &:not(:checked):after {
            transform: scale(0);
            opacity: 0;
        }

        &:checked:after {
            transform: scale(1);
            opacity: 1;
        }
    }
}

.mpErrorRow {
    color: $form-group-error-color;

    label {
        margin-bottom: 0;
    }
}

.mpMultipleLabel {
    margin-bottom: 0;
}

.submitButton {
    @extend .button;
}
