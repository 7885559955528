.article-item {
    flex: none;
    width: 100%;
    margin-bottom: 2rem;
}

.article-item--triple {
    position: relative;
    width: percentage(1/1);

    &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 25%;
        display: block;
        opacity: 0.8;
        background: linear-gradient(-45deg, $gradient-start-color 0%, $gradient-end-color 100%);
    }

    .article-item__image-holder {
        flex-shrink: 0;
        margin: 0;
        padding: 0 20px;

        &:before {
            display: none !important;
        }
    }

    .article-item__content-holder {
        z-index: 1;
        padding: 20px 20px 0;
        color: #fff;
    }

    .article-item__heading {
        color: #fff;
        font-size: $font-size-h2;
        font-weight: $heading-font-weight;
    }

    .article-item__link {
        display: flex;
        align-items: center;
        flex-direction: column;
        padding: 2rem 0;
    }
}

.article-item__link {
    display: block;
    color: inherit;
    text-decoration: none;

    &:hover {
        .article-item__image-holder {
            &:before {
                transform: translate(10px, -10px);
            }
        }

        .button {
            background-color: $brand-secondary-hover;
        }
    }
}

.article-item__holder {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.article-item__image-holder {
    @include image-background(10px, -10px);
    display: inline-block;
    max-width: 100%;
    margin-bottom: 2rem;

    @include mq($from: md) {
        min-height: 350px;
    }

    @include mq($from: xl) {
        min-height: 390px; // actual image height
    }
}

.article-item__heading {
    font-size: 1.33rem;
    font-weight: $heading-font-weight;
}

.article-item__datetime {
    position: absolute;
    z-index: $zindex-content;
    top: 20px;
    right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: $brand-secondary;

    strong {
        font-size: 1.25rem;
        font-weight: 700;
    }

    > * {
        line-height: 1;
    }
}

.article-item__content-holder {
    display: block;

    .button {
        margin-left: 0;
    }
}

@include mq($from: sm) {
    .article-item__holder {
        margin: 0 -20px;
    }

    .article-item {
        width: percentage(6/12);
        padding: 0 20px;

        &.article-item--double {
            width: percentage(1/1);
        }

        &.article-item--triple {
            width: percentage(1/1);
        }
    }
}

@include mq($from: md) {
    .article-item__holder {
        margin: 0 -60px;
    }

    .article-item {
        width: percentage(6/12);
        padding: 0 60px;

        &.article-item--double {
            width: percentage(1/1);
        }

        &.article-item--triple {
            width: percentage(1/1);
        }
    }

    .article-item__datetime {
        width: 80px;
        height: 80px;
    }

    .article-item__image-holder {
        @include image-background(20px, -20px);
        margin-bottom: 2.5rem;
    }

    .article-item__link:hover {
        .article-item__image-holder:before {
            transform: translate(20px, -20px);
        }
    }
}

@include mq($from: lg) {
    .article-item__holder {
        margin: 0 -50px;
    }

    .article-item {
        width: percentage(4/12);
        padding: 0 50px;

        &.article-item--double {
            width: percentage(8/12);
        }

        &.article-item--triple {
            width: percentage(1/1);

            &:after {
                left: 20%;
                top: 0;
                bottom: 0;
                right: 0;
            }

            .article-item__image-holder {
                flex-shrink: 0;
                width: auto;
                margin: 0;
                padding: 0;
            }

            .article-item__content-holder {
                padding: 0 120px 0 20px;
            }

            .article-item__link {
                display: flex;
                align-items: center;
                flex-direction: row;
                padding: 2rem 0;
            }
        }
    }
}

@include mq($from: xl) {
    .article-item__holder {
        margin: 0 -80px;
    }

    .article-item {
        padding: 0 80px;

        &.article-item--triple {
            width: percentage(1/1);

            &:after {
                right: 100px;
            }

            .article-item__heading {
                font-size: $font-size-h1;
            }
        }
    }
}
